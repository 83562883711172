import clsx from "clsx";
import { GalleryProvider } from "components/Gallery";
import { Image } from "components/Image";
import Main from "components/Layout/Main";
import Meta from "components/Meta";
import { SkipNavContent } from "components/SkipNav";
import { AuthorsSection } from "components/article/AuthorsSection";
import { Navigation } from "components/article/LeftAside";
import { ReferencesSection } from "components/article/ReferencesSection";
import { DrawerTools } from "components/article/aside/DrawerTools";
import Tools from "components/article/aside/Tools";
import { Header } from "components/article/header/Header";
import { PaywallBlur } from "components/article/paywall/PaywallBlur";
import { useInteractions } from "components/interactions/InteractionsContext";
import useEventualScroll from "hooks/useEventualScroll";
import { useGetPaywallType } from "hooks/useGetPaywallType";
import { t } from "locales";
import { noPrintSectionTypes } from "pages/[[...page]]";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IPage } from "data/contentData/api/content";
import DefaultPageSection from "./DefaultPageSection";
interface IProps {
  page: IPage;
  isPublic?: boolean;
}
export function DefaultPageTemplate({
  page,
  isPublic
}: IProps) {
  useEventualScroll(page);
  const {
    showTools,
    closeTools
  } = useInteractions();
  const image = {
    alt: page.image?.alt ?? page.image?.caption ?? page.gallery?.[0]?.alt ?? page.gallery?.[0]?.caption ?? "",
    src: page.image?.src ?? page.gallery?.[0]?.src
  };
  return <Main leftAside={<Navigation page={page} />} rightAside={page.template?.hasTools ? <Tools tools={page.linkLists} /> : null} data-sentry-element="Main" data-sentry-component="DefaultPageTemplate" data-sentry-source-file="DefaultPageTemplate.tsx">
            <DrawerTools onClose={closeTools} open={showTools} lists={page.linkLists} data-sentry-element="DrawerTools" data-sentry-source-file="DefaultPageTemplate.tsx" />

            <article>
                <Meta noIndex={page.noIndex} description={page.ingress} image={image} isPublic={isPublic} title={page.title} metadata={page.metadata} type={"article"} url={page.url} data-sentry-element="Meta" data-sentry-source-file="DefaultPageTemplate.tsx" />
                <SkipNavContent data-sentry-element="SkipNavContent" data-sentry-source-file="DefaultPageTemplate.tsx" />
                {page.image ? <StyledFigure className={clsx("no-print")}>
                        {/* REVIEW: Add alt text to images. (Maybe use same image format as in gallery) */}
                        <StyledImage priority width={640} height={360} quality={80} src={page.image} alt={page.image.alt || page.image.caption || ""} />

                        <Typography variant="caption" component="figcaption" px={2}>
                            {page.image.caption}
                        </Typography>
                    </StyledFigure> : null}
                <Header page={page} data-sentry-element="Header" data-sentry-source-file="DefaultPageTemplate.tsx" />
                <StyledContentWrapper data-sentry-element="StyledContentWrapper" data-sentry-source-file="DefaultPageTemplate.tsx">
                    {/*
                     * Since it is block content,
                     * we cannot modify the margin of the anchor,
                     * the header will overlap when scrolling to it
                     * @see /handboken/kliniske-kapitler/endokrinologi/tilstander-og-sykdommer/diabetes-mellitus/diabetisk-nefropati
                     */}
                    <GalleryProvider gallery={page.gallery} data-sentry-element="GalleryProvider" data-sentry-source-file="DefaultPageTemplate.tsx">
                        <ArticleContent page={page} data-sentry-element="ArticleContent" data-sentry-source-file="DefaultPageTemplate.tsx" />
                    </GalleryProvider>
                </StyledContentWrapper>
                <StyledPrintDisclaimer variant="body2" data-sentry-element="StyledPrintDisclaimer" data-sentry-source-file="DefaultPageTemplate.tsx">
                    {`${t["on-print"]["disclaimer"]}`}
                </StyledPrintDisclaimer>
                <StyledPrintDisclaimer variant="body2" data-sentry-element="StyledPrintDisclaimer" data-sentry-source-file="DefaultPageTemplate.tsx">
                    {`${t["on-print"]["copy-right"].replace("$year", new Date().getFullYear().toString())}`}
                </StyledPrintDisclaimer>
            </article>
        </Main>;
}
function ArticleContent({
  page
}: {
  page: IPage;
}) {
  useEventualScroll(page);
  const paywallType = useGetPaywallType(page);
  if (paywallType === "none") {
    return <>
                {page.sections?.map(section => <DefaultPageSection key={section.url} section={section} />)}
                <Sources page={page} />
            </>;
  }
  const previewSections = page.sections?.filter(s => s.isPreview);
  const premiumSections = page.sections?.filter(s => !s.isPreview);
  const showPaywallLayover = paywallType !== "article-public-open-no-sub" && paywallType !== "article-public-open-not-logged-in";
  return <div className="paywall" data-sentry-component="ArticleContent" data-sentry-source-file="DefaultPageTemplate.tsx">
            {previewSections?.map(section => <DefaultPageSection key={section.url} section={section} />)}

            <div style={{
      position: "relative"
    }}>
                {showPaywallLayover && <PaywallBlur />}

                {premiumSections?.map(section => <DefaultPageSection key={section.url} section={section} />)}

                <Sources page={page} data-sentry-element="Sources" data-sentry-source-file="DefaultPageTemplate.tsx" />
            </div>
        </div>;
}
const StyledPrintDisclaimer = styled(Typography)(({
  theme
}) => ({
  display: "none",
  fontStyle: "italic",
  marginTop: theme.spacing(3),
  "@media print": {
    display: "block"
  }
}));
const StyledFigure = styled("figure")(() => ({
  margin: 0
}));
const StyledImage = styled(Image)(() => ({
  objectFit: "cover",
  maxWidth: "100%",
  height: "auto"
}));
const StyledContentWrapper = styled("div")(({
  theme
}) => ({
  padding: theme.spacing(2),
  position: "relative",
  [theme.breakpoints.up("sm")]: {
    padding: 0
  }
}));
interface ISourcesProps {
  page: IPage;
}
function Sources({
  page
}: ISourcesProps) {
  if (!page.sources) {
    return null;
  }
  const {
    url
  } = page.sources;
  return <DefaultPageSection key="sources" section={page.sources} noPrint={noPrintSectionTypes.includes(page.sources.type)} data-sentry-element="DefaultPageSection" data-sentry-component="Sources" data-sentry-source-file="DefaultPageTemplate.tsx">
            {page.references && <ReferencesSection id={`${url}-references`} references={page.references} />}
            {page.authors && <AuthorsSection id={`${url}-authors`} page={page} />}
        </DefaultPageSection>;
}