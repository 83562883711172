import Collapsible from "components/Collapsible";
import { GalleryProvider } from "components/Gallery";
import Main from "components/Layout/Main";
import Meta from "components/Meta";
import PortableText from "components/PortableText/PortableText";
import { SkipNavContent } from "components/SkipNav";
import { AuthorsSection } from "components/article/AuthorsSection";
import { Navigation } from "components/article/LeftAside";
import { ReferencesSection } from "components/article/ReferencesSection";
import { DrawerTools } from "components/article/aside/DrawerTools";
import Tools from "components/article/aside/Tools";
import { Header } from "components/article/header/Header";
import { PaywallBlur } from "components/article/paywall/PaywallBlur";
import { useInteractions } from "components/interactions/InteractionsContext";
import useEventualScroll from "hooks/useEventualScroll";
import { useGetPaywallType } from "hooks/useGetPaywallType";
import { noPrintSectionTypes } from "pages/[[...page]]";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { IPage } from "data/contentData/api/content";
import getContentIds from "lib/get-sources-ids";
import { ProfessionalPageSection } from "./ProfessionalPageSection";
interface IProps {
  page: IPage;
  isPublic?: boolean;
}
export function ProfessionalPageTemplate({
  page,
  isPublic
}: IProps) {
  const {
    showTools,
    closeTools
  } = useInteractions();
  const {
    regionalContentId
  } = getContentIds(page);
  return <Main leftAside={page.template?.hasNavigation ? <Navigation page={page} /> : null} rightAside={page.template?.hasTools ? <Tools tools={page.linkLists} regionalContentId={regionalContentId} /> : null} data-sentry-element="Main" data-sentry-component="ProfessionalPageTemplate" data-sentry-source-file="ProfessionalPageTemplate.tsx">
            <DrawerTools onClose={closeTools} open={showTools} lists={page.linkLists} regionalContentId={regionalContentId} data-sentry-element="DrawerTools" data-sentry-source-file="ProfessionalPageTemplate.tsx" />

            <Grid container component="article" data-sentry-element="Grid" data-sentry-source-file="ProfessionalPageTemplate.tsx">
                <Meta noIndex={page.noIndex} authors={page.authors} dateModified={page.updated} datePublished={page.published} isPublic={isPublic} title={page.title} metadata={page.metadata} type="article" url={page.url} data-sentry-element="Meta" data-sentry-source-file="ProfessionalPageTemplate.tsx" />
                <Grid item xs id="article-content" data-sentry-element="Grid" data-sentry-source-file="ProfessionalPageTemplate.tsx">
                    <SkipNavContent data-sentry-element="SkipNavContent" data-sentry-source-file="ProfessionalPageTemplate.tsx" />
                    <Header page={page} data-sentry-element="Header" data-sentry-source-file="ProfessionalPageTemplate.tsx" />
                    <GalleryProvider gallery={page.gallery} data-sentry-element="GalleryProvider" data-sentry-source-file="ProfessionalPageTemplate.tsx">
                        <ArticleContent page={page} data-sentry-element="ArticleContent" data-sentry-source-file="ProfessionalPageTemplate.tsx" />
                    </GalleryProvider>
                </Grid>
            </Grid>
        </Main>;
}
function ArticleContent({
  page
}: {
  page: IPage;
}) {
  useEventualScroll(page);
  const paywallType = useGetPaywallType(page);
  if (paywallType === "none") {
    return <>
                {page.sections?.map(section => <ProfessionalPageSection key={section.url} section={section} />)}
                <SourcesSection page={page} />
            </>;
  }
  const previewSections = page.sections?.filter(s => s.isPreview);
  const premiumSections = page.sections?.filter(s => !s.isPreview);
  return <div className="paywall" data-sentry-component="ArticleContent" data-sentry-source-file="ProfessionalPageTemplate.tsx">
            {previewSections?.map(section => <ProfessionalPageSection key={section.url} section={section} isPreview />)}

            <StyledDiv data-sentry-element="StyledDiv" data-sentry-source-file="ProfessionalPageTemplate.tsx">
                <PaywallBlur data-sentry-element="PaywallBlur" data-sentry-source-file="ProfessionalPageTemplate.tsx" />

                {premiumSections?.map(section => <ProfessionalPageSection key={section.url} section={section} />)}

                <SourcesSection page={page} data-sentry-element="SourcesSection" data-sentry-source-file="ProfessionalPageTemplate.tsx" />
            </StyledDiv>
        </div>;
}
const StyledDiv = styled("div")({
  position: "relative"
});
function SourcesSection({
  page
}: {
  page: IPage;
}) {
  const {
    authorsContentId,
    referencesContentId
  } = getContentIds(page);

  // If page.sources does NOT exist => references & authors does NOT exist aswell!
  if (!page.sources) {
    return null;
  }
  const {
    blockContent,
    url,
    name,
    heading,
    sections,
    type,
    collapsed
  } = page.sources;
  return <Collapsible id={url} level={2} title={name} heading={heading} collapsed={collapsed} noPrint={noPrintSectionTypes.includes(type)} isTopPanel data-sentry-element="Collapsible" data-sentry-component="SourcesSection" data-sentry-source-file="ProfessionalPageTemplate.tsx">
            {blockContent ? <PortableText value={blockContent} wrapImgWithGalleryLink /> : null}
            {sections?.map(subSection => {
      const subSectionBlockContent = subSection.blockContent ? <PortableText key={subSection.url} value={subSection.blockContent} wrapImgWithGalleryLink /> : null;
      if (subSection.name) {
        return <Collapsible key={subSection.name} id={subSection.url} level={3} title={subSection.name} heading={subSection.heading} collapsed={subSection.collapsed}>
                            {subSectionBlockContent}
                        </Collapsible>;
      }
      return subSectionBlockContent;
    }) ?? null}
            {page.references && <ReferencesSection references={page.references} template="Professional" id={referencesContentId} />}
            {page.authors && <AuthorsSection page={page} id={authorsContentId} />}
        </Collapsible>;
}